import dayjs from 'dayjs';
import type { AddressbaseFilter, OpeningHoursFilter } from '@gql/schema';
import { PoiFilterProperty } from '@models/BaseFilterInput';
import type { TimeRange } from '@models/TimeRange';
import type { PoiSearchModel } from '../stores/searchStore';

export default (
  stateRef: MaybeRefOrGetter<PoiSearchModel>,
  ignoreFieldsRef?: MaybeRefOrGetter<PoiFilterProperty[]>
): Ref<AddressbaseFilter> => {
  return computed((): AddressbaseFilter => {
    const state: PoiSearchModel = toValue(stateRef);
    const ignoreFields: PoiFilterProperty[] = toValue(ignoreFieldsRef) ?? [];

    const dayTimeRanges: TimeRange[] =
      state.daytime?.map(mapTimeOfDayToRange) ?? [];

    const openingHoursFilter: OpeningHoursFilter = {
      openAt: undefined,
      openOn: undefined,
      openToday: undefined,
    };
    if (
      !isEmpty([state.dateFrom, state.dateTo], 'some') &&
      state.dateFrom === state.dateTo
    ) {
      const today = dayjs().format('YYYY-MM-DD');
      if (state.dateFrom === today) {
        openingHoursFilter.openToday = true;
      } else {
        openingHoursFilter.openOn = {
          oneOf: [getWeekdayFromDate(state.dateFrom!)],
        };
      }
    } else if (!isEmpty([state.dateFrom, state.dateTo], 'some')) {
      openingHoursFilter.openOn = {
        oneOf: [
          getWeekdayFromDate(state.dateFrom!),
          getWeekdayFromDate(state.dateTo!),
        ],
      };
    }

    const openingHoursDayTimeFilter = {
      or: dayTimeRanges.map((daytime) => ({
        openingHours: {
          openAtTimeOfDay: { from: daytime.from, to: daytime.to },
        },
      })),
    };

    const userFilter: AddressbaseFilter = {
      //fulltext: state.search.join(' '), <- deprecated
      productlines: {
        oneOf: state.categories,
        allOf: state.criteria,
      },
      and: [{ openingHours: openingHoursFilter }, openingHoursDayTimeFilter],
      location: state.locationId ? { eq: state.locationId } : undefined,
      group:
        state.locationType === 'AddressPoiGroup'
          ? { oneOf: [state.locationId].filter((id) => isDefined(id)) }
          : undefined,
      regions:
        state.locationType === 'Region'
          ? { oneOf: [state.locationId].filter((id) => isDefined(id)) }
          : undefined,
      geoFilter: {
        distanceFromPoint:
          state.latitude && state.longitude
            ? {
                point: {
                  latitude: state.latitude,
                  longitude: state.longitude,
                },
                maxDistance: state.radius || 5000,
              }
            : null,
      },
    };

    if (
      (ignoreFields as PoiFilterProperty[]).indexOf(
        PoiFilterProperty.CATEGORY
      ) !== -1
    ) {
      delete userFilter.productlines?.oneOf;
    }
    if (
      (ignoreFields as PoiFilterProperty[]).indexOf(
        PoiFilterProperty.ATTRIBUTE
      ) !== -1
    ) {
      delete userFilter.productlines?.allOf;
    }

    return userFilter;
  });
};
