import dayjs from 'dayjs';
import type { EventFilter } from '@gql/schema';
import { EventFilterProperty } from '@models/BaseFilterInput';
import type { TimeRange } from '@models/TimeRange';
import type { EventSearchModel } from '../stores/searchStore';

export default (
  stateRef: MaybeRefOrGetter<EventSearchModel>,
  ignoreFieldsRef?: MaybeRefOrGetter<EventFilterProperty[]>
): Ref<EventFilter> => {
  return computed((): EventFilter => {
    const state: EventSearchModel = toValue(stateRef);
    const ignoreFields: EventFilterProperty[] = toValue(ignoreFieldsRef) ?? [];

    const dayTimeRanges: TimeRange[] = state.daytime?.map(mapTimeOfDayToRange);

    const userFilter: EventFilter = {
      fromDate: state.dateFrom
        ? dayjs(state.dateFrom).format('YYYY-MM-DD')
        : undefined,
      toDate: state.dateTo
        ? dayjs(state.dateTo).format('YYYY-MM-DD')
        : undefined,
      or: dayTimeRanges.map((daytime) => ({
        startTime: { from: daytime.from, to: daytime.to },
      })),
      pricingFilter: state.onlyFree ? { freeOfCharge: true } : undefined,
      categories: !isEmpty(state.categories)
        ? { oneOf: state.categories }
        : undefined,
      criteria: !isEmpty(state.criteria)
        ? { allOf: state.criteria }
        : undefined,
      eventLocation: {
        regions:
          state.locationType === 'Region'
            ? {
                oneOf: [state.locationId].filter((id) => isDefined(id)),
              }
            : undefined,
        location:
          state.locationType === 'Location'
            ? { eq: state.locationId }
            : undefined,
        group:
          state.locationType === 'AddressPoiGroup'
            ? { oneOf: [state.locationId].filter((id) => isDefined(id)) }
            : undefined,
      },
      geoFilter: {
        distanceFromPoint:
          state.latitude && state.longitude
            ? {
                point: {
                  latitude: state.latitude,
                  longitude: state.longitude,
                },
                maxDistance: state.radius ? state.radius : 5000,
              }
            : null,
      },
      onlyOneOffs: state.hasSingleEvent ? true : undefined,
    };

    if (
      (ignoreFields as EventFilterProperty[]).indexOf(
        EventFilterProperty.CATEGORY
      ) !== -1
    ) {
      delete userFilter.categories;
    }
    if (
      (ignoreFields as EventFilterProperty[]).indexOf(
        EventFilterProperty.CRITERION
      ) !== -1
    ) {
      delete userFilter.criteria;
    }

    return userFilter;
  });
};
