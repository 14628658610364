import dayjs from 'dayjs';
import { TimeOfDay } from '@models/TimeOfDay';
import type { TimeRange } from '@models/TimeRange';

export default (daytime: TimeOfDay): TimeRange => {
  switch (daytime) {
    case TimeOfDay.MORNING:
      return {
        from: dayjs().hour(6).minute(0).second(0).format('HH:mm:ss'),
        to: dayjs().hour(12).minute(0).second(0).format('HH:mm:ss'),
      };
    case TimeOfDay.AFTERNOON:
      return {
        from: dayjs().hour(12).minute(0).second(0).format('HH:mm:ss'),
        to: dayjs().hour(18).minute(0).second(0).format('HH:mm:ss'),
      };
    case TimeOfDay.EVENING:
      return {
        from: dayjs().hour(18).minute(0).second(0).format('HH:mm:ss'),
        to: dayjs().hour(23).minute(59).second(59).format('HH:mm:ss'),
      };
    default:
      throw new Error(`Unknown daytime value: ${daytime}`);
  }
};
