import type { Nullable } from '@models/CustomUtilityTypes';
import type { WhlPageType } from '@models/WhlPageType';

export default (): Ref<Nullable<WhlPageType>> => {
  const route = useRoute();

  return computed((): Nullable<WhlPageType> => {
    if (route.meta?.whlPageType) {
      return route.meta.whlPageType as WhlPageType;
    }
    return null;
  });
};
